<template>
  <section class="main" v-if="!isLoading">
    <div>
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="is-size-3"><b>Assessment Setup</b></h1>
        </div>
        <div class="column bar">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <h3 class="is-size-6">Assessment</h3>
              </li>
              <li>
                <router-link :to="{ name: `assessment` }">
                  <h3 class="is-size-6">Assessment Setup</h3>
                </router-link>
              </li>
              <li>
                <h3 class="is-size-6 breadcrumb-item">Assessment Detail</h3>
              </li>
            </ul>
          </nav>
        </div>
        <!-- <div class="column bar">
          <router-link
            :to="{ path: `/assessment/edit/${$route.params.assessmentId}` }"
          >
            <b-field grouped position="is-right">
              <b-button class="is-hcc" native-type="submit">Edit</b-button>
            </b-field>
          </router-link>
        </div> -->
      </div>
      <div class="columns">
        <div class="column is-12">
          <div class="box">
            <AssessmentInformation :data="information" />
            <br />
            <br />
            <!-- </div>
          <div class="box"> -->
            <AssessmentSettings :data="settings" />
            <br />
            <br />
            <!-- </div>
          <div class="box" v-if="isUseRules"> -->
            <template v-if="isUseRules">
              <AssessmentRule :data="rule" />
              <br />
              <br />
            </template>
            <!-- </div> -->

            <!-- For question use rules or not -->
            <!-- <b-field grouped position="is-right">
            <span style="padding-right: 10px">Use Rules :</span>
            {{ isUseRules }}
          </b-field> -->

            <!-- <div class="box"> -->
            <AssessmentThankYouPage :data="thankYouPage" />
            <br />
            <br />
            <!-- </div>
          <div class="box"> -->
            <AssessmentListSection :data="listSection" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AssessmentInformation from '@/components/AssessmentDetail/AssessmentInformation'
import AssessmentThankYouPage from '@/components/AssessmentDetail/AssessmentThankYouPage'
import AssessmentRule from '@/components/AssessmentDetail/AssessmentRule'
import AssessmentSettings from '@/components/AssessmentDetail/AssessmentSettings'
import AssessmentListSection from '@/components/AssessmentDetail/AssessmentListSection'

export default {
  components: {
    AssessmentInformation,
    AssessmentThankYouPage,
    AssessmentRule,
    AssessmentSettings,
    AssessmentListSection,
  },
  name: 'detail-assessment',
  data() {
    return {
      // For Animated Change
      isAnimated: true,

      // For Active Step
      activeStep: 0,

      // For Loading Page
      isLoading: false,

      // For step success
      isProfileSuccess: true,

      // For Information Data
      information: {
        title: '',
        description: '',
        category: [],
      },

      // For Introduction Data
      thankYouPage: {
        title: '',
        description: '',
      },

      rule: {
        endDate: '',
        startDate: '',
      },

      settings: {
        screenCaptureTime: '',
        screenShotTime: '',
      },

      listSection: {
        section: [],
      },

      isUseRules: true,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // For get assessment detail data
    async fetchData() {
      this.isLoading = true
      const response = await this.$store.dispatch(
        'getAssessment',
        this.$route.params.assessmentId
      )
      this.information = response.data.information
      this.thankYouPage = response.data.thankYouPage
      this.rule = response.data.rule
      this.settings = response.data.settings
      this.isUseRules = response.data.isUseRules
      this.rule.endDate = new Date(this.rule.endDate)
      this.rule.startDate = new Date(this.rule.startDate)
      this.listSection.section = response.data.section
      this.isLoading = false
    },
  },
}
</script>
