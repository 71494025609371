<template>
  <div>
    <h1 class="title">Thank You Page</h1>
    <!-- <div class="box"> -->
    <!-- For Title Field -->
    <div class="columns is-12 separator-line">
      <div class="column is-2">
        <b>Title</b>
      </div>
      <div class="column is-10">
        {{ data.title }}
      </div>
    </div>

    <!-- For Rules Description -->
    <div class="columns is-12">
      <div class="column is-2">
        <b>Description</b>
      </div>
      <div class="column is-10">
        <div v-html="data.description"></div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  },
  data() {
    return {
      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: "div[class]",
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: "justify,font"
      }
    };
  }
};
</script>
