<template>
  <div>
    <h1 class="title">Settings</h1>
    <!-- For screen apture time -->
    <div class="columns is-12 separator-line">
      <div class="column is-2">
        <b>Interval Screen Capture</b>
      </div>
      <div class="column is-10">{{ data.screenCaptureTime }} Seconds</div>
    </div>

    <!-- For screen record time -->
    <div class="columns is-12">
      <div class="column is-2">
        <b>Interval Candidate's Photo</b>
      </div>
      <div class="column is-10">{{ data.screenShotTime }} Seconds</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
