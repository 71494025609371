<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-10">
        <h1 class="title">Information</h1>
      </div>
      <div class="column is-2" v-if="$route.name != 'showCaseStudy'">
        <router-link
          :to="{ path: `/assessment/edit/${$route.params.assessmentId}` }"
        >
          <b-field grouped position="is-right">
            <b-button class="is-hcc" native-type="submit">Edit</b-button>
          </b-field>
        </router-link>
      </div>
      <div class="column is-2" v-else>
        <router-link
          :to="{ path: `/case-study/edit/${$route.params.caseStudyId}` }"
        >
          <b-field grouped position="is-right">
            <b-button class="is-hcc" native-type="submit">Edit</b-button>
          </b-field>
        </router-link>
      </div>
    </div>
    <div class="columns is-multiline separator-line">
      <div class="column is-2">
        <b>Page Title</b>
      </div>
      <div class="column is-10">
        {{ data.title }}
      </div>
    </div>
    <div class="columns">
      <div class="column is-2">
        <b>Page Description</b>
      </div>
      <div class="column is-10">
        {{ data.description }}
      </div>
    </div>
    <div class="columns">
      <div class="column is-2">
        <b>Category</b>
      </div>
      <div class="column is-10">
        {{ data.category[0] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
