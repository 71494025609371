<template>
  <div>
    <h1 class="title ">Period Time</h1>
    <!-- <div class="box"> -->
    <div class="columns is-multiline separator-line">
      <!-- <div class="column is-2">
        <b>Periode</b>
      </div> -->
      <div class="column is-2">
        <b>Start Period</b>
      </div>
      <div class="column is-10">
        {{ data.startDate }}
      </div>
      <div class="column is-2">
        <b>End Period</b>
      </div>
      <div class="column is-10">
        {{ data.endDate }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
