<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-10">
        <h1 class="title">Section List</h1>
      </div>
      <div class="column is-2">
        <b-field grouped position="is-right">
          <b-button
            class="icon-button"
            @click="createSection"
            title="Add Section"
          >
            Add Section
          </b-button>
        </b-field>
      </div>
      <div class="column is-12  separator-line">
        <b-table :data="data.section">
          <b-table-column
            field="no"
            numeric
            width="10"
            label="No"
            v-slot="props"
          >
            {{ props.index + 1 }}
          </b-table-column>
          <b-table-column
            field="name"
            width="650"
            label="Name"
            centered
            v-slot="props"
          >
            {{ props.row.name }}
          </b-table-column>
          <b-table-column
            field="action"
            width="200"
            label="Action"
            v-slot="props"
          >
            <router-link
              :to="{
                path: `/assessment/show-section/${$route.params.assessmentId}/${props.row.id}`,
              }"
            >
              <b-button
                class="icon-button"
                icon-left="eye"
                title="Detail Section"
              ></b-button>
            </router-link>
            &nbsp;
            <router-link
              :to="{
                path: `/assessment/edit-section/${$route.params.assessmentId}/${props.row.id}`,
              }"
            >
              <b-button
                class="icon-button"
                icon-left="pencil-alt"
                title="Edit Section"
              ></b-button>
            </router-link>
            <b-button
              class="icon-button"
              icon-left="copy"
              title="Duplicate Section"
              @click="duplicateSectionPopup(props.row.id)"
            ></b-button>
            <b-button
              class="icon-button"
              icon-left="trash"
              title="Delete Section"
              @click="deleteSectionPopup(props.row.id)"
            ></b-button>
            <!-- <b-button type="is-danger">Delete</b-button> -->
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="frown" size="is-large"></b-icon>
                </p>
                <p>Data not found.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
  methods: {
    // For get assessment detail data
    async fetchData() {
      this.isLoading = true
      const response = await this.$store.dispatch(
        'getAssessment',
        this.$route.params.assessmentId
      )
      this.data.section = response.data.section
      this.isLoading = false
    },

    createSection() {
      this.$router.push({ name: 'addAssessmentSection' })
    },

    // For delete section popup
    deleteSectionPopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Delete Section',
        message: `Are you sure want to delete this section?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteSection(id),
      })
    },

    // For delete section
    deleteSection(id) {
      this.$store
        .dispatch('deleteAssessmentSection', { id })
        .then(() => {
          this.fetchData()
          this.success('Successfully delete section')
        })
        .catch(() => {
          this.danger('Failed to delete section')
        })
    },

    // For popup duplicate section
    duplicateSectionPopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Duplicate Section',
        message: `Are you sure want to duplicate this section?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, duplicate it!',
        type: 'is-hcc',
        onConfirm: () => this.duplicateSectionTest(id),
      })
    },

    // For duplicate section
    duplicateSectionTest(id) {
      this.$store
        .dispatch('duplicateAssessmentSection', { id })
        .then(() => {
          this.fetchData()
          this.success('Successfully duplicate section')
        })
        .catch(() => {
          this.danger('Failed to duplicate section')
        })
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>
